<template>
  <div id="app">
      <b-navbar toggleable="lg" type="light" variant="light" fixed="top" v-show="$route.name!='read'">
        <b-navbar-brand href="#">
            Push Man
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item v-for="(value,index) in menu" :key="index" v-bind:active="$route.name==value" @click="$router.push({name: value})">{{ $t('common.'+value) }}</b-nav-item>

          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto"><!--
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
            </b-nav-form>-->

            <b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item @click="lang('zh')" v-bind:active="$i18n.locale=='zh'"><country-flag country='cn' size='small'/>{{$t('common.lang.zh')}}</b-dropdown-item>
              <b-dropdown-item @click="lang('en')" v-bind:active="$i18n.locale=='en'"><country-flag country='usa' size='small'/>{{$t('common.lang.en')}}</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                User
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item href="#">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

    <router-view class="container" v-bind:style="$route.name!='read'?'margin-top: 55px':''"></router-view>


    <footer class="text-muted" style="margin-top: 30px">
      <div class="container">
        <p class="float-right">
          <a href="#">Back to top</a>
        </p>
        <p>2021 &copy; Push Man All right served.</p>
        <p> Wanna make contact or need help? Read our <router-link to="doc">one step doc</router-link> or <a href="/">Visit contact</a>.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'App',
  components: {
    CountryFlag
  },
  data(){
    return {
      menu:['home','apps','send','commands','doc','donate']

    }
  },
  methods:{
    lang(lang){
      this.$i18n.locale = lang;
    }

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.normal-flag {
}
.flag{
  width: 60px;
  height: 40px;
}
</style>
