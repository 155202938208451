<template>
<div>
  APPsaaaaaaaaaaaaaaaaaaaaa.
  <h1>aaaaaaaaaaaaaaaaaaaaa</h1>
</div>
</template>

<script>
export default {
  name: "Apps"
}
</script>

<style scoped>

</style>
