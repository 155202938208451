<template>
<div style="margin-top: 10px">
  <h3>{{ title }}</h3>
  <hr/>
  <mavon-editor
      class="md"
      :value="read"
  :subfield = "prop.subfield"
  :defaultOpen = "prop.defaultOpen"
  :toolbarsFlag = "prop.toolbarsFlag"
  :editable="prop.editable"
  :scrollStyle="prop.scrollStyle"
  ></mavon-editor>
  <div style="margin-top: 10px;" class="footer"><span>Push Man</span></div>
</div>
</template>

<script>
export default {
  name: "Apps",
  data(){
    return {
      msg: '',
      que:'',
      title: '',
      read: '**加载中**'
    }
  },
  mounted() {
    this.msg = this.$route.query.msg;
    this.que = this.$route.query.que;
    let that = this;
    this.$axios.get(this.apiurl +'/read?msg='+this.msg+'&que='+this.que)
        .then(function (response) {
          console.log(response);
          if(response.data.status==2) {
            window.location.href = response.data.url;
          }
          if(response.data.status==1){
            that.title = response.data.title;
            that.read = response.data.content;
          }else{

            that.title = "错误";
            that.read = "###参数丢失，或消息已经不存储在服务器上！";
          }
        })
        .catch(function (error) {
          console.log(error);
        });

  },
  computed: {
    prop () {
      let data = {
        subfield: false,// 单双栏模式
        defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true
      }
      return data
    }
  }
}
</script>

<style scoped>
</style>
