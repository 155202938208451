import Vue from 'vue'
import store from '@/store/index'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router';
import App from './App.vue'
import axios from 'axios'
import qs from 'qs'
Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.apiurl = "https://mp.application.pub/api"    //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs           //全局注册，使用方法为:this.qs
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import HelloWorld from "@/components/HelloWorld";
import Apps from "@/components/Apps";
import Send from "@/components/Send";
import Commands from "@/components/Commands";
import Doc from "@/components/Doc";
import Donate from "@/components/Donate";
import Read from "@/components/Read";
Vue.use(VueRouter);
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)
let router = new VueRouter({
  routes: [
    //一个个对象
    { path: '/home', component: HelloWorld, name: 'home' },
    { path: '/apps', component: Apps, name: 'apps' },
    { path: '/send', component: Send, name: 'send' },
    { path: '/commands', component: Commands, name: 'commands' },
    { path: '/doc', component: Doc, name: 'doc' },
    { path: '/donate', component: Donate, name: 'donate' },
    { path: '/read', component: Read, name:'read' },
    {
      path: '/',
      redirect: '/home'
    }
  ]
});

Vue.use(VueI18n)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false
const i18n = new VueI18n({

  locale: 'zh',

  messages: {

    'zh': require('@/lang/zh.json'),

    'en': require('@/lang/en.json')

  }

});

new Vue({
  render: h => h(App),
  i18n,
  store,
  router: router,
}).$mount('#app')
