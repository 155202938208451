import Vue from 'vue'    //引入vue
import Vuex from 'vuex' //引入vue
//使用vuex
Vue.use(Vuex)

//创建Vuex实例
const store = new Vuex.Store({
    state:{
        ak:""    //保存的数据
    },
    mutations:{

    }
})

export default store    //导出store
